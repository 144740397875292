import { $accountingOAuth, $settingsAccounting, availableAcctIntegrations, fetchAndSetOrganizationAcctProviders, getAccountingOAuthUrl, handleAccountingOAuthCallback } from 'components/views/SettingsAccounting/SettingsAccounting.helpers';
import { useEffect } from 'react';
import ConnectionModal from 'components/views/SettingsAccounting/_components/ConnectionModal';
import useOAuthCallback from 'hooks/useOAuthCallback';
import { handleNotification } from 'components/global/Alert/Alert';
import Loader from 'components/global/Loader';
import AcctConnectionCard from 'components/views/SettingsAccounting/_components/ConnectionCard';
import DisconnectionModal from 'components/views/SettingsAccounting/_components/DisconnectModal';

const SettingsConnections = () => {
  const { dispatchOAuth } = useOAuthCallback({
    signal: $accountingOAuth,
    onSuccess: handleAccountingOAuthCallback,
    onFailure: () => handleNotification('Something went wrong while connecting your accounting software. Please try again.', { variant: 'danger' }),
  });

  const handleConnect = async (provider) => {
    const url = await getAccountingOAuthUrl(provider);
    dispatchOAuth(url, provider);
  };

  useEffect(() => {
    fetchAndSetOrganizationAcctProviders();
  }, []);

  const { connections, isLoading } = $settingsAccounting.value;
  if (isLoading || !connections?.items) return <Loader className="d-flex align-items-center justify-content-center my-40" />;

  return (
    <div>
      <h1>Accounting Software Sync</h1>
      {availableAcctIntegrations.map((acct, i) => (
        <AcctConnectionCard acct={acct} isLoading={isLoading} handleConnect={handleConnect} key={i} />
      ))}
      <ConnectionModal />
      <DisconnectionModal />
    </div>
  );
};

export default SettingsConnections;
