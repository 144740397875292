import history from 'utils/history';
import $selectOrganization from 'components/views/SelectOrganization/SelectOrganization.signals';
import { selectOrganization } from 'components/helpers/User.helpers';

export const handleSelectOrganizationModalOpen = () => {
  $selectOrganization.update({
    isModalOpen: true,
  });
};

export const handleSelectOrganizationModalClose = () => {
  $selectOrganization.reset();
};

export const handleSelectOrganizationSearchClear = () => {
  $selectOrganization.reset();
};

export const handleSelectOrganizationListClick = (organizationId) => {
  selectOrganization(organizationId);

  history.push('/');

  handleSelectOrganizationModalClose();
};
