import Signal from './Signal';

export const $user = Signal({
  user: {
    first_name: '',
    last_name: '',
    organization: null,
  },

  organizationOverride: null,

  selectedOrganizationId: null,
  isSyncModalOpen: false,
});

export default $user;
