import { useEffect } from 'react';
import { Button, Card, Col, Container, Dropdown, Image, Row } from 'react-bootstrap';
import filterAlt from 'images/filter-alt.svg';
import connectionsDisconnect from 'images/connectionsDisconnect.svg';
import connectionsViewDetails from 'images/connectionsViewDetails.svg';
import connectionsResend from 'images/connectionsResend.svg';
import successCircle from 'images/successCircle.svg';
import alertCircle from 'images/alertCircle.svg';
import failedCircle from 'images/failedCircle.svg';
import Loadable from 'components/global/Loadable';
import { $settingsConnections, fetchAndSetConnectionsData, handleConnAccept, handleConnReject, handleInvite } from 'components/views/SettingsConnections/SettingsConnections.helpers';
import { faCheck, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';

const SettingsConnections = () => {
  const { connections, status } = $settingsConnections.value;

  useEffect(() => {
    (async () => { await fetchAndSetConnectionsData(); })();
  }, [status]);

  const pendingIncomingConnections = connections.filter(conn => conn.is_valid && conn._currentSideData.conn_status === 'INVITED');

  return (
    <div>
      <h1 className="d-none d-lg-block d-xl-block">Connections</h1>
      <Row className="mx-0 bg-gray-50 rounded mt-32 pb-16">
        <Container>
          <Row className="mx-8 mb-24 mt-16">
            <Col sm={10} className="p-0">
              <h2 className="mb-0 fw-700 fw-bold">Connection Requests</h2>
            </Col>
          </Row>
          <Row className="mx-0 px-0 mb-24">
            <Loadable signal={$settingsConnections}>
              {pendingIncomingConnections.length ? pendingIncomingConnections.map((conn) => (
                <Card className="mx-8 border border-primary" key={conn.id}>
                  <Row className="py-16 px-24 mx-0">
                    <Col sm={3} className="px-0 my-auto">
                      <Col sm={12}><span className="text-dark fw-bold">{conn._otherSideData.name}</span></Col>
                      <Col sm={12}><span className="fst-italic fw-light">as your {conn._otherSide}</span></Col>
                    </Col>
                    <Col sm={4} className="px-0 text-dark my-auto fw-350">
                      Invitation sent {moment(conn._currentSideData.conn_status_at).format('l')}
                    </Col>
                    <Col sm={5} className="px-0 d-flex justify-content-around">
                      <Button
                        type="button"
                        variant="primary"
                        className="w-100 me-8"
                        disabled={$settingsConnections.value.isLoading}
                        onClick={() => handleConnAccept({ organizationConnectionId: conn.id })}
                      >
                        <FontAwesomeIcon icon={faCheck} className="me-8" height={18} /> Accept
                      </Button>
                      <Button
                        type="button"
                        variant="outline-primary"
                        className="w-100 ms-8"
                        disabled={$settingsConnections.value.isLoading}
                        onClick={() => handleConnReject({ organizationConnectionId: conn.id })}
                      >
                        <FontAwesomeIcon icon={faXmark} className="me-8" height={18} /> Reject
                      </Button>
                    </Col>
                  </Row>
                </Card>
              )) : (
                <Card className="mx-8">
                  <Row className="py-16 px-24 mx-0">
                    <Col sm={12} className="px-0 my-auto">
                      <span className="text-dark fw-bold">No pending connection requests</span>
                    </Col>
                  </Row>
                </Card>
              )}
            </Loadable>
          </Row>
        </Container>
      </Row>
      <Row className="mx-0 bg-gray-50 rounded mt-32 pb-16">
        <Container>
          <Row className="mx-8 mb-24 mt-16">
            <Col sm={11} className="p-0">
              <h2 className="mb-0 fw-700 fw-bold">My Connections</h2>
            </Col>
            <Col sm={1} className="p-0 my-auto">
              <Dropdown>
                <Button
                  variant="eye"
                  className="p-0 bg-white text-primary my-auto"
                >
                  <Dropdown.Toggle variant="eye" className="custom-dropdown-toggle">
                    <Image src={filterAlt} alt="filter-alt" />
                  </Dropdown.Toggle>
                </Button>
                <Dropdown.Menu className="custom-nav-dropdown p-0">
                  <Dropdown.Item className="rounded" disabled={$settingsConnections.value.isLoading} onClick={() => $settingsConnections.update({ status: 'ACCEPTED' })}>Accepted</Dropdown.Item>
                  <Dropdown.Item className="rounded" disabled={$settingsConnections.value.isLoading} onClick={() => $settingsConnections.update({ status: 'REJECTED' })}>Declined</Dropdown.Item>
                  <Dropdown.Item className="rounded" disabled={$settingsConnections.value.isLoading} onClick={() => $settingsConnections.update({ status: 'INVITED' })}>Pending</Dropdown.Item>
                  <Dropdown.Item className="rounded" disabled={$settingsConnections.value.isLoading} onClick={() => $settingsConnections.update({ status: null })}>None</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Col>
          </Row>
          <Row className="mx-0 px-0 mb-24">
            <Loadable signal={$settingsConnections}>
              <Card className="m-0 p-0 mx-8 px-16">
                <Row className="py-8 mx-8">
                  <Col sm={3} className="p-0 text-dark fw-900">
                    With
                  </Col>
                  <Col sm={6} className="p-0 text-dark fw-900">
                    Status
                  </Col>
                  <Col sm={3} className="p-0 text-dark fw-900 ps-16">
                    Action
                  </Col>
                </Row>
              </Card>
              {connections?.length ? connections.filter(conn => conn.is_valid && !pendingIncomingConnections.some(pending => pending.id === conn.id)).map((conn) => (
                <Card key={conn.id} className="mx-8 border mt-8">
                  <Row className="py-16 px-24 mx-0">
                    <Col sm={3} className="px-0 my-auto">
                      <Col sm={12}><span className="text-dark fw-bold">{conn._otherSideData.name}</span></Col>
                      <Col sm={12}><span className="fst-italic fw-light">as your {conn._otherSide}</span></Col>
                    </Col>
                    <Col sm={6} className="px-0 text-dark my-auto fw-350">
                      {conn.is_connected ? (
                        <Row className="m-0 p-0">
                          <Row className="m-0 p-0">
                            <Col sm={1} className="p-0 my-auto">
                              <Image src={successCircle} alt="successCircle" height="16px" className="w-auto ps-0" />
                            </Col>
                            <Col sm={11} className="p-0">
                              <Row className="m-0 p-0 text-dark fw-350">
                                Connected
                              </Row>
                              <Row className="m-0 p-0 text-dark fw-350">
                                {conn.connectedTimestamp}
                              </Row>
                            </Col>
                          </Row>
                        </Row>
                      ) : (
                        <Row className="m-0 p-0">
                          <Row className="m-0 p-0">
                            <Col sm={1} className="p-0 my-auto">
                              {conn._currentSideData.conn_status === 'REJECTED' || conn._otherSideData.conn_status === 'REJECTED' ?
                                (<Image src={failedCircle} alt="failedCircle" height="16px" className="w-auto ps-0" />) :
                                (<Image src={alertCircle} alt="alertCircle" height="16px" className="w-auto ps-0" />)}
                            </Col>
                            <Col sm={11} className="p-0">
                              {conn._currentSideData.conn_status === 'REJECTED' && (<Col sm={12}><span className={conn._currentSideData.conn_status === 'REJECTED' ? 'text-danger' : ''}>Rejected by you at {moment(conn._currentSideData.conn_status_at).format('l')}</span></Col>)}
                              <Col sm={12}>
                                <span className={conn._otherSideData.conn_status === 'REJECTED' ? 'text-danger' : ''}>
                                  {conn._otherSideData.conn_status === 'INVITED' && `Awaiting ${conn._otherSideData.name} since `}
                                  {conn._otherSideData.conn_status === 'ACCEPTED' && `Accepted by ${conn._otherSideData.name} at `}
                                  {conn._otherSideData.conn_status === 'REJECTED' && `Rejected by ${conn._otherSideData.name} at `}
                                  {moment(conn._otherSideData.conn_status_at).format('l')}
                                </span>
                              </Col>
                            </Col>
                          </Row>
                        </Row>
                      )}
                    </Col>
                    <Col sm={3} className="px-0 text-dark my-auto fw-350">
                      {conn.is_connected && (
                        <Button
                          variant="eye"
                          className="mx-auto text-danger text-decoration-underline"
                          disabled={$settingsConnections.value.isLoading}
                          onClick={() => handleConnReject({ organizationConnectionId: conn.id })}
                        >
                          <Image src={connectionsDisconnect} height="18px" className="me-8" />
                          Disconnect
                        </Button>
                      )}
                      {(conn._currentSideData.conn_status === 'REJECTED') ? (
                        <Button
                          variant="eye"
                          className="text-primary text-decoration-underline"
                          disabled={$settingsConnections.value.isLoading}
                          onClick={() => handleConnAccept({ organizationConnectionId: conn.id })}
                        >
                          <Image src={connectionsViewDetails} height="14px" className="me-8" />
                          Accept
                        </Button>
                      ) : (
                        (conn._otherSideData.conn_status !== 'ACCEPTED') && (
                          <Button
                            variant="eye"
                            className="text-primary text-decoration-underline"
                            disabled={$settingsConnections.value.isLoading}
                            onClick={() => handleInvite({ type: conn._otherSide, targetId: conn._currentSideData.target_id })}
                          >
                            <Image src={connectionsResend} height="14px" className="me-8" />
                            Resend Invite
                          </Button>
                        )
                      )}
                    </Col>
                  </Row>
                </Card>
              )) : (
                <Card className="mx-8 mt-8">
                  <Row className="py-16 px-24 mx-0">
                    <Col sm={12} className="px-0 my-auto">
                      <span className="text-dark fw-bold">No connections found</span>
                    </Col>
                  </Row>
                </Card>
              )}
            </Loadable>
          </Row>
        </Container>
      </Row>
    </div>
  );
};

export default SettingsConnections;
