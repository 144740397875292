/* eslint-disable no-unused-vars */
import { Route } from 'react-router-dom';
import ContentWrapper from 'components/global/ContentWrapper';
import Redirect from 'components/global/Redirect';
import { $global } from 'signals/Global.signals';
import NavBarPublic from 'components/global/NavBarPublic';
import Loader from 'components/global/Loader';
import { reconnectServer } from 'components/views/Auth/_shared/Auth.helpers';
import { useEffect } from 'react';
import { historyPush } from 'utils/history';
import { Button } from 'react-bootstrap';

const PublicRoute = ({ component: Component, restricted, allowSigned = false, useNavbarPublic = false, ...rest }) => {
  const { isBackendConnDown } = $global.value;
  useEffect(() => {
    let timeout;

    if (isBackendConnDown) {
      const retryConnection = async () => {
        const isConnected = await reconnectServer();
        if (!isConnected) timeout = setTimeout(retryConnection, 5000);
      };

      retryConnection();

      return () => {
        if (timeout) clearTimeout(timeout);
      };
    }
  }, [isBackendConnDown]);

  if (isBackendConnDown) {
    return (
      <div>
        <div className="min-vh-100 w-100 d-flex justify-content-center align-items-center flex-grow-1 flex-column">
          <h1>Oops, something went wrong!</h1>
          <Loader message="Reconnecting..." className="text-center" />
          <h1 className="mb-16">or</h1>
          <Button onClick={() => historyPush('/login')}>Try Again</Button>
        </div>
      </div>
    );
  }

  if ($global.value?.isLoading) {
    return (
      <div>
        <div className="min-vh-100 w-100 d-flex justify-content-center align-items-center flex-grow-1">
          <Loader message={$global.value?.isLoadingMessage ?? 'Loading...'} className="text-center" />
        </div>
      </div>
    );
  }

  if (!allowSigned && $global.value.isSignedIn) {
    return <Redirect />;
  }

  return (
    <Route
      {...rest}
      render={(props) => (
        <>
          {useNavbarPublic && <NavBarPublic />}
          <ContentWrapper fluid className="min-vh-100 px-0">
            <Component {...props} />
          </ContentWrapper>
        </>
      )}
    />
  );
};

export default PublicRoute;
