import { Card, Row, Col, Button, Form, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const SelectOrganizationCard = ({ organization, role, onSelect, isSelected, ...rest }) => (
  <OverlayTrigger
    key="overlay-trigger"
    placement="bottom"
    show={role !== 'OWNER' && organization?.subscription_level === 1}
    overlay={(
      <Tooltip id="tooltip-bottom" className="d-block">
        Access to this organization is limited on the Free Plan. Only the Owner can manage it. Request an organization upgrade for access.
      </Tooltip>
    )}
  >
    <Card
      onClick={(e) => {
        if (role !== 'OWNER' && organization?.subscription_level === 1) {
          e.preventDefault();
          e.stopPropagation();
        } else {
          onSelect(organization);
        }
      }}
      className={`border bg-gray-50 rounded-4 py-16 px-32 cursor-pointer mb-16 ${isSelected ? 'border-1 border-info' : 'row-hover-effect'}`}
      {...rest}
    >
      <Row className="align-items-center m-0">
        <Col className="p-0">
          <h5 className="text-dark-900 fw-700">{organization.name}</h5>
        </Col>
      </Row>
      <Row className="align-items-center m-0">
        {isSelected ? (
          <Col className="p-0 align-items-center">
            <Form.Check
              checked
              readOnly
              inline
              className="m-0 p-0 border-0"
            />
            <span className="m-0 p-0 base fw-500 text-dark ms-8">Currently signed in</span>
          </Col>
        ) : (
          <Col className="p-0">
            <Button
              variant="link"
              disabled={role !== 'OWNER' && organization?.subscription_level === 1}
              className="p-0 m-0 border-0 text-decoration-none"
            >

              <span className="fw-500 base text-decoration-underline text-primary">Sign in here</span> <FontAwesomeIcon className="text-primary" icon={faArrowRight} />
            </Button>
          </Col>
        )}
      </Row>
    </Card>
  </OverlayTrigger>
);

export default SelectOrganizationCard;
