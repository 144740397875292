import { Image } from 'react-bootstrap';
import loader from 'images/accruGif.gif';

const Loader = ({
  message = '',
  width = '100',
  className,
}) => (
  <div className={className}>
    <Image src={loader} width={width} alt="loader" />
    {message && (
      <h4 className="mt-16">{message}</h4>
    )}
  </div>
);

export default Loader;
