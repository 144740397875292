/* eslint-disable import/prefer-default-export */
export const US_STATES = [
  'AL', 'AK', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'FL', 'GA', 'HI', 'ID', 'IL',
  'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MD', 'MA', 'MI', 'MN', 'MS', 'MO', 'MT',
  'NE', 'NV', 'NH', 'NJ', 'NM', 'NY', 'NC', 'ND', 'OH', 'OK', 'OR', 'PA', 'RI',
  'SC', 'SD', 'TN', 'TX', 'UT', 'VT', 'VA', 'WA', 'WV', 'WI', 'WY',
];

export const US_STATES_WITH_LABELS = () => {
  const res = US_STATES.map(state => ({
    label: state,
    value: state,
  }));
  res.unshift({ label: 'Select...', value: null });
  return res;
};

export const validateState = (input) => {
  if (!US_STATES.includes(input)) {
    throw new Error('Invalid state.');
  }
};

export const validateZip = (zipCode) => {
  if (!/^\d{5}$/.test(zipCode)) {
    throw new Error('Invalid zip code');
  }
};

export const validateTaxIdCode = (taxIdCode) => {
  if (!/^\d{9}$/.test(taxIdCode)) {
    throw new Error('Invalid Tax ID Number');
  }
};
