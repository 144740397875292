import { Route, useLocation } from 'react-router-dom';
import NavBar from 'components/global/NavBar/NavBar';
import ContentWrapper from 'components/global/ContentWrapper';
import Redirect from 'components/global/Redirect';
import { $global } from 'signals/Global.signals';
import Loader from 'components/global/Loader';
import { useEffect } from 'react';
import { reconnectServer } from 'components/views/Auth/_shared/Auth.helpers';
import $user from 'signals/User.signals';
import { Button } from 'react-bootstrap';
import { historyPush } from 'utils/history';
import isForbiddenOrganizationUser from 'utils/isForbiddenOrganizationUser';
import { findOrganization, isImpersonating, isOnboardingRequired, selectOrganization } from 'components/helpers/User.helpers';

const PrivateRoute = ({ component: Component, noNavbar, path, ...rest }) => {
  const { isSignedIn, isBackendConnDown, isLoading } = $global.value;
  const { currentOrganization } = $user.value;
  const location = useLocation();

  useEffect(() => {
    let timeout;

    if (isBackendConnDown) {
      const retryConnection = async () => {
        const isConnected = await reconnectServer();
        if (!isConnected) timeout = setTimeout(retryConnection, 5000);
      };

      retryConnection();

      return () => {
        if (timeout) clearTimeout(timeout);
      };
    }
  }, [isBackendConnDown]);

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const queryOrganizationId = urlParams.get('organization_id');
    const localStorageOrganizationId = localStorage.getItem('accru:organization_id');

    const organizationId = queryOrganizationId || localStorageOrganizationId;

    if (isSignedIn && !!currentOrganization?.id && organizationId && currentOrganization.id !== organizationId) {
      const organization = findOrganization(organizationId);
      if (organization && !isImpersonating.value) selectOrganization(organizationId);
    }
  }, [isSignedIn, currentOrganization, location.search]);

  if (!isSignedIn && !isLoading) {
    return <Redirect to="/login" />;
  }

  if ($global.value?.isLoading) {
    return (
      <div>
        <div className="min-vh-100 w-100 d-flex justify-content-center align-items-center flex-grow-1">
          <Loader message={$global.value?.isLoadingMessage ?? 'Loading...'} className="text-center" />
        </div>
      </div>
    );
  }

  if (isBackendConnDown) {
    return (
      <div>
        <div className="min-vh-100 w-100 d-flex justify-content-center align-items-center flex-grow-1 flex-column">
          <h1>Oops, something went wrong!</h1>
          <Loader message="Reconnecting..." className="text-center" />
          <h1 className="mb-16">or</h1>
          <Button onClick={() => historyPush('/customers')}>Try Again</Button>
        </div>
      </div>
    );
  }

  const onboardRequired = !!isOnboardingRequired.value || currentOrganization?.subscriptionLevel === 0;
  if (onboardRequired && path !== '/onboarding') return <Redirect to="/onboarding" />;
  if (!onboardRequired && path === '/onboarding') return <Redirect to="/customers" />;
  if (path !== '/profile' && isForbiddenOrganizationUser()) return <Redirect to="/profile" />;

  return (
    <Route
      {...rest}
      render={(props) => (
        <ContentWrapper fluid className="min-vh-100 px-0">
          {!noNavbar && (
            <div className="pb-40">
              <NavBar />
            </div>
          )}
          <Component {...props} />
        </ContentWrapper>
      )}
    />
  );
};

export default PrivateRoute;
