import Loadable from 'components/global/Loadable';
import { Form } from 'react-bootstrap';

const zipFormatter = (value) => {
  const cleanedZip = value.replace(/\D/g, '');
  if (cleanedZip.length > 5) {
    const formattedZIP = `${cleanedZip.slice(0, 5)}-${cleanedZip.slice(5)}`;
    return formattedZIP;
  }
  return cleanedZip;
};

const ZipInput = ({
  name,
  variant = 'form-control',
  value,
  signal,
  loadableAnimation,
  className,
}) => {
  if (!signal || !name) {
    return new Error(`ZipInput has no signal or name (Name: ${name})`);
  }
  return (
    <Loadable signal={signal} animation={loadableAnimation} className={className}>
      <Form.Control
        className={`${variant} bg-white`}
        id={name}
        type="text"
        placeholder="Zip Code"
        name={name}
        value={zipFormatter(value) || zipFormatter(signal.value[name])}
        onChange={(e) => {
          if (e.target.value.length < 11) {
            signal.update({
              [name]: e.target.value,
            });
          }
        }}
      />
    </Loadable>
  );
};

export default ZipInput;
