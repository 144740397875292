/* eslint-disable */
import { Card, Col, Container, Row, Image } from 'react-bootstrap';
import { $settingsProfileUI } from 'components/views/SettingsProfile/_helpers/SettingsProfile.signals';
import BasicInfo from './_components/BasicInfo';
import UpdatePassword from './_components/UpdatePassword';
import ContactInfo from './_components/ContactInfo';
import ContentWrapper from 'components/global/ContentWrapper';
import basicInfoImg from 'images/profileBasicInfo.svg';
import contactInfoImg from 'images/profileContactInfo.svg';
import passwordInfoImg from 'images/profilePassword.svg';
import SettingsModal from 'components/views/Settings/_components/SettingsModal';
import { useHistory } from 'react-router-dom';
import useWindowSize from 'hooks/useWindowSize';
import { useEffect } from 'react';
import logEvent from 'utils/logEvent';

const profileTabs = [
  {
    id: 1,
    route: 'basic-info',
    tab: 'basic-info',
    icon: basicInfoImg,
    title: 'Basic Info',
    description: 'Update name, profile picture...',
    component: <BasicInfo />,
  },
  {
    id: 2,
    route: 'contact-info',
    tab: 'contact-info',
    icon: contactInfoImg,
    title: 'Contact Info',
    description: 'Update email, phone number...',
    component: <ContactInfo />,
  },
  {
    id: 3,
    route: 'password',
    tab: 'password',
    icon: passwordInfoImg,
    title: 'Password',
    description: 'Update password...',
    component: <UpdatePassword />,
  },
  
];

const SettingsProfile = () => {
  const { currentTab } = $settingsProfileUI.value;

  const history = useHistory();
  const { isBreakpointDown } = useWindowSize();
  const isMobile = isBreakpointDown('md');

  useEffect(() => {
    const found = profileTabs.find(obj => obj.route === history.location.pathname.replace('/profile/', ''));
    if (found) {
      logEvent(`view_profile_${found.tab}`);
      $settingsProfileUI.update({
        ...$settingsProfileUI.value,
        currentTab: found.tab,
        isMobileModalOpen: isMobile,
      });
    }
  }, [history.location.pathname, isMobile]);


  return (
    <ContentWrapper>
      <Container fluid className="min-vh-100">
        <Container fluid>
          <Row>
            <Col xs={12} lg={5} className="min-vh-100 left-panel-border pe-lg-48 py-32 pt-lg-88">
              <Row className="me-24 mb-md-32">
                <Col className="d-flex justify-content-between align-items-center">
                  <h1 className="fw-400 mb-0">Profile Settings</h1>
                </Col>
              </Row>
              {profileTabs && profileTabs.map((obj, idx) => (
                  <Card
                    key={idx}
                    className={`p-16 w-100 h-auto ${currentTab === obj.tab ? 'border border-2 border-info' : 'row-hover-effect border-gray'} ${obj.id !== 1 ? 'mt-24' : ''}`}
                    onClick={() => history.push(`/profile/${obj.route}`)}
                  >
                    <Row>
                      <Col xs={2} className="d-flex justify-content-center">
                        <Image src={obj.icon} height={28} width={28} />
                      </Col>
                      <Col xs={10} className={`ps-0`}>
                        <h3 className={`fw-700 text-dark mb-16`}>{obj.title}</h3>
                        <h5 className={`fw-400 text-dark mb-0`}>{obj.description}</h5>
                      </Col>
                    </Row>
                  </Card>
                )
              )}
            </Col>
            <SettingsModal settingTabs={profileTabs} />
            <Col lg={7} className="ps-48 mt-88 d-none d-lg-block">
              {profileTabs && profileTabs.map((obj, idx) => {
                if (obj.tab === currentTab) {
                  return (
                    <div key={idx}>
                      {obj.component}
                    </div>
                  );
                }
                return '';
              })}
            </Col>
          </Row>
        </Container>
      </Container>
    </ContentWrapper>
  );
};

export default SettingsProfile;
