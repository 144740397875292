/* eslint-disable no-unreachable */
/* eslint-disable no-use-before-define */
import moment from 'moment';
import history from 'utils/history';
import { handleNotification } from 'components/global/Alert/Alert';
import $user from 'signals/User.signals';
import logEvent from 'utils/logEvent';
import openBase64Pdf from 'utils/openBase64Pdf';
import { fetchStatementData } from 'components/views/Statement/statementFetch.helpers';
import { fetchPDFBase64, getAccountProviderName, getPaymentLink } from 'components/views/Statement/statementViewPay.helpers';
import {
  $filterStatement,
  $listStatement,
  $uiStatement,
  $detailStatement,
} from './statement.signals';

export async function fetchAndSetStatement() {
  $uiStatement.update({ selectedItems: [], isTableLoading: true });
  $detailStatement.reset();
  $detailStatement.loadingStart();
  const {
    pathName,
    pathParams,
    queryParams,
  } = $uiStatement.value;

  try {
    if (!pathName || !pathParams || !queryParams || !(queryParams instanceof URLSearchParams)) throw new Error('Invalid request.');
    const targetId = pathParams.id;
    if (!targetId) throw new Error('Invalid target id.');

    const params = {
      currency: $filterStatement.value.currency,
      // startDate: moment($filterStatement.value.startDate).local(),
      // endDate: moment($filterStatement.value.endDate).local(),
      isOverdue: $filterStatement.value.isOverdue,

      after: $filterStatement.value.after,
      first: $filterStatement.value.first,

      before: $filterStatement.value.before,
      last: $filterStatement.value.last,

      skip: $filterStatement.value.skip,
      take: $filterStatement.value.take,
    };
    if ($filterStatement.value.sortKey) {
      params.sorting = [
        { field: $filterStatement.value.sortKey, order: $filterStatement.value.sortDirection?.toUpperCase() },
      ];
    }

    if ($filterStatement.value.status) {
      const status = $filterStatement.value.status.toUpperCase();
      if (status === 'OVERDUE') {
        params.isOverdue = true;
      } else if (status === 'ALL') {
        delete params.status;
      } else {
        params.status = status;
      }
    }
    const data = await fetchStatementData({
      targetId,
      organizationId: $user.value.currentOrganization.id,
      type: $uiStatement.value.activeTab,
      viewerMode: getViewerMode(),
      params,
      unconnectedRequestData: {
        token: queryParams.get('token'),
        email: queryParams.get('email'),
        expires_at: moment(queryParams.get('expires_at')),
      },
    });

    $detailStatement.update(data);

    $listStatement.update({ items: data.items });

    logEvent({ name: 'view_statement' });
  } catch (error) {
    handleNotification(error);
    $filterStatement.reset();
    $listStatement.reset();
    history.replace(pathName.includes('/customers') ? '/customers' : '/vendors');
    $listStatement.loadingEnd();
  } finally {
    $listStatement.loadingEnd();
    $uiStatement.update({ isTableLoading: false });
    $detailStatement.loadingEnd();
  }
}

export function handleClickInvoiceCountItem(status) {
  $uiStatement.update({
    activeTab: 'invoice',
  });
  $filterStatement.update({
    status,
  });
}

export async function handleClickTabToggle() {
  const type = $uiStatement.value.activeTab === 'invoice' ? 'history' : 'invoice';
  const sortKey = $uiStatement.value.activeTab === 'invoice' ? 'date' : 'invoice_date';
  $uiStatement.update({
    activeTab: type,
  });
  $filterStatement.update({
    type,
    sortKey,
    sortDirection: 'desc',
    status: 'Open',
  });
}

export const handleClickViewPay = async (item) => {
  // Todo: add a loading indicator since this take a minute
  // eslint-disable-next-line no-unreachable
  try {
    const acctProviderName = getAccountProviderName({ item });

    if (item.status === 'OPEN') {
      const paymentLink = getPaymentLink({ item }); // This might not work. If it doesn't work
      window.open(paymentLink, '_blank');
    } else {
      const pdfBase64 = await fetchPDFBase64({
        item,
        viewerMode: getViewerMode(),
        acctProviderName,
        currentOrganizationId: $user.value.currentOrganization.id,
        targetId: $uiStatement.value.pathParams.id,
        unconnectedRequestData: {
          token: $uiStatement.value.queryParams.get('token'),
          email: $uiStatement.value.queryParams.get('email'),
          expires_at: moment($uiStatement.value.queryParams.get('expires_at')),
        },
      });
      if (pdfBase64) { openBase64Pdf(pdfBase64); } else { throw new Error('Fail to open pdf.'); }
      return;
    }
  } catch (error) {
    handleNotification(error);
  }
};

export const isStatementItemPaid = (item) => item.paid_amount >= item.amount;

function getViewerMode() {
  const isLogged = !!$user?.value?.currentOrganization?.id;
  const { pathName } = $uiStatement.value;
  let viewerMode = null;
  if (isLogged && pathName.includes('/customers')) {
    viewerMode = 'VENDOR';
  } else if (isLogged && pathName.includes('/vendors')) {
    viewerMode = 'CUSTOMER';
  } else {
    viewerMode = 'UNCONNECTED_CUSTOMER';
  }

  return viewerMode;
}

export const handleCustomerSync = async () => {
  // TODO: Implement syncing logic LUIZ
  $uiStatement.update({ isSyncing: true });
  setTimeout(() => {
    $uiStatement.update({ isSyncing: false });
    handleNotification('Syncing is complete', { variant: 'success' });
  }, 2000);
};
