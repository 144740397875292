import { Image } from 'react-bootstrap';
import moment from 'moment';
import checkIcon from 'images/checkIcon.svg';
import dueIcon from 'images/dueIcon.svg';
import xIcon from 'images/xIcon.svg';

const StatusString = ({ bill }) => {
  const daysDifference = moment().diff(moment(bill.due_date), 'days');
  if (bill.status === 'paid') { // cannot implement until payment module complete
    return (
      <div className="align-items-center d-flex">
        <Image src={checkIcon} height={13} />
        <small className="text-success ps-8">Paid</small>
      </div>
    );
  } if (daysDifference <= 0) {
    return (
      <div className="align-items-center d-flex">
        <Image src={dueIcon} height={13} />
        <small className="text-warning ps-8">
          {' '}Due {daysDifference === 0 ? 'today' : `in ${Math.abs(daysDifference)} days`}
        </small>
      </div>
    );
  }
  return (
    <div className="align-items-center d-flex">
      <Image src={xIcon} height={13} />
      <small className="text-danger ps-8">{' '}Overdue {daysDifference} days</small>
    </div>
  );
};

export default StatusString;
