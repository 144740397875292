import { Row, Col, Container, Card, Image } from 'react-bootstrap';
import plusImg from 'images/plus.svg';
import cycleImg from 'images/cycle.svg';
import { isProdVersion } from 'components/views/MVP/MVP.helpers';
import { $syncing } from 'signals/Global.signals';
import { useHistory } from 'react-router-dom';

const InvoiceMethodScreen = ({ handleNext }) => {
  const history = useHistory();
  return (
    <>
      <Row>
        <Col className="text-center">
          <h1 className="text-primary fw-700 pt-16 pb-24">How do you want to add invoices?</h1>
        </Col>
      </Row>
      <Row>
        <Col>
          <h2 className="pb-56">To send payment to your clients, first add invoices to accru.</h2>
        </Col>
      </Row>
      <Container className="pb-48">
        <Card
          onClick={() => {
            $syncing.update({ referredBy: 'invoices' });
            history.push('/sync');
          }}
          style={{ maxWidth: 620 }}
          className="cursor-pointer py-32 mb-24 justify-content-left mx-auto"
        >
          <Container>
            <Row className="d-flex justify-content-left align-items-center px-16">
              <Col xs={2}>
                <Image width={54} src={cycleImg} />
              </Col>
              <Col xs={9} className="text-start text-dark">
                <h5 className="fw-700">Connect your accounting software</h5>
                <p className="base mb-0">Import and sync all your invoices</p>
              </Col>
            </Row>
          </Container>
        </Card>
        <Card
          onClick={isProdVersion(2) ? () => handleNext({ current: 'method', next: 'form' }) : null}
          style={{ maxWidth: 620 }}
          className={`py-32 mb-24 justify-content-left mx-auto ${isProdVersion() ? 'opacity-50' : 'cursor-pointer'}`}
        >
          <Container>
            <Row className="d-flex justify-content-left align-items-center px-16">
              <Col xs={2}>
                <Image width={54} src={plusImg} />
              </Col>
              <Col xs={9} className="text-start text-dark">
                <h5 className="fw-700">Add invoices yourself</h5>
                <p className="base mb-0">Upload or add invoices manually</p>
                <p>{isProdVersion() && 'Coming soon!'}</p>
              </Col>
            </Row>
          </Container>
        </Card>
      </Container>
    </>
  );
};

export default InvoiceMethodScreen;
