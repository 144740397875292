import { Button, Col, Form, Image, Row } from 'react-bootstrap';
import { $settingsReminder, DUE_REMINDER_DEFAULT_DATA, fetchAndSetReminderSettings, handleOrganizationBaseSettingChange, remindersPerPage } from 'components/views/SettingsReminders/SettingsReminders.helpers.js';
import plusSign from 'images/plusSign.svg';
import ReminderCard from 'components/views/SettingsReminders/_components/ReminderCard';
import Pagination from 'components/global/Pagination';

const InvoiceReminders = () => {
  const {
    isLoading,

    dueReminderSettings,
    dueReminderSettingsPage,

    enableDueReminders,
    // allowSnoozeDueReminders

    createData,
  } = $settingsReminder.value;

  return (
    <Row className="mx-0">
      <Row className="mx-0 mt-24 bg-gray-50 rounded py-16">
        <Row className="m-0 px-0">
          <Col sm={10} className="p-0">
            <h2 className="mb-0 fw-700">Automatic Invoice Reminders</h2>
          </Col>
          <Col sm={2} className="p-0 d-flex justify-content-center align-items-center">
            <Form>
              <Form.Check
                className="form-check-radio-custom"
                type="switch"
                disabled={isLoading}
                checked={!!enableDueReminders}
                onChange={(e) => handleOrganizationBaseSettingChange('enableDueReminders', e.target.checked)}
              />
            </Form>
          </Col>
        </Row>
        {!enableDueReminders && (
          <Row className="p-0 m-0 pt-8">
            <p className="p-0 m-0 text-dark">Automatic invoice reminders apply to all new invoices. Turning off automatic invoice reminders will disable them all.</p>
          </Row>
        )}
      </Row>
      {enableDueReminders && (
        <>
          <Row className="mx-0 mt-24 bg-gray-50 rounded py-16">
            <h2 className="mb-0 p-0 fw-700">Reminders</h2>
            {!!dueReminderSettings?.items && dueReminderSettings.items.map((obj, idx) => (
              <ReminderCard reminder={obj} index={idx} dueType="due" displayMode="normal" key={idx} />
            ))}

            {!!dueReminderSettings?.items && !!createData && (
              <ReminderCard index={dueReminderSettings.items.length} dueType="due" displayMode="create" />
            )}

            {!createData && (
              <Row className="mx-0 mt-8 d-flex justify-content-end">
                <Button
                  className="mb-0 w-auto"
                  variant="eye"
                  disabled={isLoading}
                  onClick={() => $settingsReminder.update({ createData: DUE_REMINDER_DEFAULT_DATA })}
                >
                  <Image src={plusSign} alt="plus-sign" className="me-8" />{dueReminderSettings?.items && dueReminderSettings?.items.length > 0 ? 'Add another' : 'Create new'} reminder
                </Button>
              </Row>
            )}

            {!!dueReminderSettings?.items && (
              <Pagination
                className="pe-24"
                itemsPerPageAmount={remindersPerPage}
                paginationMaxButtonAmount={5}
                totalItemsCount={dueReminderSettings.totalCount}
                currentPageItemsCount={dueReminderSettings.items.length}
                currentPage={dueReminderSettingsPage + 1}
                setCurrentPage={(page) => {
                  fetchAndSetReminderSettings({ duePage: page - 1 });
                }}
                disabled={isLoading}
              />
            )}
          </Row>
          {/* NOT MVP
          <Row className="mx-0 mt-24 bg-gray-50 rounded py-16">
            <Row className="m-0 p-0">
              <Col sm={10} className="p-0">
                <h2 className="mb-0 fw-700">Allow Customers to Snooze</h2>
              </Col>
              <Col sm={2} className="p-0 d-flex justify-content-center align-items-center">
                <Form>
                  <Form.Check
                    className="form-check-radio-custom"
                    type="switch"
                    name="allowSnoozeDueReminders"
                    checked={!!allowSnoozeDueReminders}
                    onChange={(e) => handleOrganizationBaseSettingChange('allowSnoozeDueReminders', e.target.checked)}
                  />
                </Form>
              </Col>
            </Row>
            <Row className="m-0 px-0 pt-8">
              <p className="p-0 m-0 text-dark">Accru will not send any reminders for invoices where your customers have opted to snooze notifications for 14 days.</p>
              <p className="p-0 m-0 mt-16 text-dark">You will still be able to manually send reminders from the invoices interface</p>
            </Row>
          </Row>
          */}
        </>
      )}
    </Row>
  );
};

export default InvoiceReminders;
