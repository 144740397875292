import { accruClient } from 'api';
import { handleNotification } from 'components/global/Alert/Alert';
import $user from 'signals/User.signals';

export const getAllConnections = async ({ status }) => {
  try {
    const data = await accruClient.connections.get({
      organizationId: $user.value.currentOrganization.id,
      ...(!!status && { status }),
    });

    return data;
  } catch (error) {
    handleNotification(error);
    throw error;
  }
};

export const getConnection = async ({ organizationConnectionId }) => {
  try {
    const data = await accruClient.connections.getOne({
      organizationId: $user.value.currentOrganization.id,
      organizationConnectionId,
    });

    return data;
  } catch (error) {
    handleNotification(error);
    throw error;
  }
};

export const inviteCustomer = async ({ organizationCustomerId }) => {
  try {
    const data = await accruClient.connections.inviteCustomer({
      organizationId: $user.value.currentOrganization.id,
      organizationCustomerId,
    });

    return data;
  } catch (error) {
    handleNotification(error);
    throw error;
  }
};

export const inviteVendor = async ({ organizationVendorId }) => {
  try {
    const data = await accruClient.connections.inviteVendor({
      organizationId: $user.value.currentOrganization.id,
      organizationVendorId,
    });

    return data;
  } catch (error) {
    handleNotification(error);
    throw error;
  }
};

export const acceptConnection = async ({ organizationConnectionId }) => {
  try {
    const data = await accruClient.connections.acceptConnection({
      organizationId: $user.value.currentOrganization.id,
      organizationConnectionId,
    });

    return data;
  } catch (error) {
    handleNotification(error);
    throw error;
  }
};

export const rejectConnection = async ({ organizationConnectionId }) => {
  try {
    const data = await accruClient.connections.rejectConnection({
      organizationId: $user.value.currentOrganization.id,
      organizationConnectionId,
    });

    return data;
  } catch (error) {
    handleNotification(error);
    throw error;
  }
};
