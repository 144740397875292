/* eslint-disable no-param-reassign */
import { signal } from '@preact/signals-react';

/**
 * @template T
 * @typedef {Object} SignalOutput
 * @property {T} value - The value returned by the Signal function.
 * @property {function} loadingStart - Sets the isLoading Property to true
 * @property {function} loadingEnd - Sets the isLoading Property to false
 * @property {function} peek - Gets the value of the singal by prop
 * @property {function} reset - Resets the value of the signal to the original value established at creation
 * @property {function} update - Updates the value of the signal without needing to spread props
 */

const updateSignal = (signalToUpdate, newVal) => {
  signalToUpdate.previousValue = signalToUpdate.value;
  if (!signalToUpdate) {
    throw new Error('Signal is not defined');
  }

  if (Array.isArray(signalToUpdate.value)) {
    signalToUpdate.value = [...newVal];
  } else if (typeof signalToUpdate.value === 'object') {
    signalToUpdate.value = {
      ...signalToUpdate.value,
      ...newVal,
    };
  } else {
    signalToUpdate.value = newVal;
  }

  return signal;
};

class SignalManager {
  constructor() {
    if (!SignalManager.instance) {
      this.signals = [];
      SignalManager.instance = this;
    }
    return SignalManager.instance;
  }

  createSignal(initState, shouldPersist = false) {
    const rawSignal = signal(initState);
    rawSignal.previousState = null;
    if (shouldPersist) {
      rawSignal.value.shouldPersist = true;
    }

    rawSignal.update = (payload) => updateSignal(rawSignal, payload);

    rawSignal.reset = () => {
      rawSignal.value = initState;
      if (shouldPersist) {
        rawSignal.value.shouldPersist = true;
      }
    };

    rawSignal.loadingStart = () => updateSignal(rawSignal, { isLoading: true });
    rawSignal.loadingEnd = () => updateSignal(rawSignal, { isLoading: false });

    this.signals.push(rawSignal);
    return rawSignal;
  }

  resetAllSignals() {
    this.signals.forEach((s) => {
      if (!s.value.shouldPersist) {
        s.reset();
      }
    });
  }
}

export const signalManager = new SignalManager(); // for use when resetting all signals

const createSignal = signalManager.createSignal.bind(signalManager);
export default createSignal; // for use when creating signals
