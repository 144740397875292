import { accruClient } from 'api';
import moment from 'moment/moment';
import $user from 'signals/User.signals';
import { handleNotification } from 'components/global/Alert/Alert';
import formatCurrency from 'utils/formatCurrency';
import {
  $invoice,
  $invoicesUI,
  $invoicesCustomerList,
  $invoices,
  $invoicesFilter,
  $invoicesCustomerOptions,
} from './Invoices.signals';

// Todo: Move signals to helper or stop exporting from here
export {
  $invoice,
  $invoicesUI,
  $invoicesCustomerList,
  $invoices,
};

export const sendInvoiceEmail = async (params) => {
  try {
    const response = await accruClient.invoices.sendEmail({
      organizationId: $user?.value?.currentOrganization?.id,
      targets: params.targets.map(targets => ({
        organization_invoice_id: targets.organizationInvoiceId,
        send_to_base_customer_email: targets.sendToPrimaryContact,
        contact_ids: targets.contactIds,
      })),
    });
    const emails = Object.entries(response).flatMap(([, value]) => value).filter((email, index, arr) => arr.indexOf(email) === index);
    const firstEmails = emails.slice(0, 3);
    handleNotification(`Sent to ${firstEmails.join(', ')}${emails.length > 3 ? ` and ${emails.length - 3} more` : ''}`, { variant: 'success' });
    return response;
  } catch (error) {
    handleNotification(error);
    throw error;
  }
};

export const getInvoice = async (params) => {
  try {
    const response = await accruClient.invoices.getOne({
      organizationId: params.organizationId,
      organizationInvoiceId: params.organizationInvoiceId,
    });
    return response;
  } catch (error) {
    handleNotification(`Error retrieving invoice ${params.organizationInvoiceId}: ${error.message}`, { variant: 'danger' });
    throw error;
  }
};

export function getStatusAndIsOverdue(status) {
  if (status === 'overdue' || status === 'open') {
    return {
      status: 'OPEN',
      isOverdue: status === 'overdue' ? true : undefined,
    };
  }

  if (status === 'all') {
    return {
      status: undefined,
      isOverdue: undefined,
    };
  }

  if (status === 'paid') {
    return {
      status: 'PAID',
      isOverdue: undefined,
    };
  }

  return {
    status: undefined,
    isOverdue: undefined,
  };
}

export async function fetchAndSetInvoices() {
  try {
    $invoicesUI.update({
      isTableLoading: true,
    });
    $invoices.loadingStart();

    const { status, isOverdue } = getStatusAndIsOverdue($invoicesFilter.value.status);

    const data = await accruClient.invoices.get({
      status,
      isOverdue,
      selectedCurrency: null,
      organizationId: $user?.value?.currentOrganization?.id,
      organizationCustomerId: $invoicesFilter.value.organizationCustomerId === 'all' ? null : $invoicesFilter.value.organizationCustomerId,
      skip: ($invoicesFilter.value.page - 1) * $invoicesFilter.value.take,
      take: $invoicesFilter.value.take,
      sorting: ($invoicesFilter.value.sortKey && $invoicesFilter.value.sortDirection)
        ? {
          field: $invoicesFilter.value.sortKey || undefined,
          order: $invoicesFilter.value.sortDirection?.toUpperCase() || undefined,
        }
        : undefined,
      startDate: $invoicesFilter.value.startDate ? moment($invoicesFilter.value.startDate).local() : undefined,
      endDate: $invoicesFilter.value.endDate ? moment($invoicesFilter.value.endDate).local() : undefined,
    });

    $invoices.update(data);
  } catch (error) {
    handleNotification(error, { variant: 'danger' });
  } finally {
    $invoicesUI.update({ selectedItems: [], isTableLoading: false });
    $invoices.loadingEnd();
  }
}

export async function fetchAndSetCustomerOptions() {
  try {
    $invoicesCustomerOptions.loadingStart();

    const customers = await accruClient.customers.get({
      organizationId: $user.value.currentOrganization.id,
    });
    const options = customers.edges.map(({ node: { id, name } }) => ({
      label: name, value: id,
    }));
    $invoicesCustomerOptions.update({
      options,
    });
  } catch (error) {
    handleNotification(error, { variant: 'danger' });
  } finally {
    $invoicesCustomerOptions.loadingEnd();
  }
}

export const exportInvoicesCsv = (invoices) => {
  const csvContent = invoices.map((inv) => ({
    invoiceNumber: inv.number,
    invoiceDate: moment(inv.invoice_date).format('l'),
    dueDate: moment(inv.due_date).format('l'),
    customerName: inv.organization_customer?.name,
    customerEmail: inv.organization_customer?.email,
    description: inv.description,
    status: inv.status,
    isOverdue: inv.is_overdue,
    amount: formatCurrency({ amountCents: Number(inv.total_amount) }),
    balance: formatCurrency({ amountCents: Number(inv.balance) }),
    emailSent: !!inv.email_sent_at,
  })).reduce((acc, curr) => `${acc}${Object.values(curr).join(',')}\n`, 'data:text/csv;charset=utf-8,Invoice Number,Invoice Date,Due Date,Customer Name,Customer Email,Description,Status,Is Overdue,Amount,Balance,Email Sent\n');

  const encodedUri = encodeURI(csvContent);
  window.open(encodedUri);
};

export async function handleBulkActionConfirm() {
  $invoicesUI.update({ isBulkActionsLoading: true });

  try {
    if ($invoicesUI.value.selectedBulkAction === 'Send All') {
      await sendInvoiceEmail({
        targets: $invoices.value.items.filter((item) => $invoicesUI.value.selectedItems?.find(({ id }) => id === item.id)).map((inv) => ({
          organizationInvoiceId: inv.id,
          sendToPrimaryContact: true,
          contactIds: [], // TODO: maybe add a option to select contacts
        })),
      });
      await fetchAndSetInvoices();
    }

    if ($invoicesUI.value.selectedBulkAction === 'Export CSV') {
      const csvData = $invoicesUI.value.selectedItems.map(({ data }) => data);
      exportInvoicesCsv(csvData);
    }
  } catch (error) {
    handleNotification(error, { variant: 'danger' });
  } finally {
    $invoicesUI.update({ isBulkActionsLoading: false, isBulkActionShow: false, selectedBulkAction: null });
  }
}
