/* eslint-disable jsx-a11y/no-static-element-interactions */
import { Row, Col } from 'react-bootstrap';
import { $selectedVendor, $vendorsUI } from 'components/views/Vendors/_helpers/Vendors.signals';
import { faX } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SidePanelConnections from 'components/global/SidePanel/SidePanelConnections';
import Loadable from 'components/global/Loadable';
import SimpleModal from 'components/global/SimpleModal/SimpleModal';

const VendorConnectionModal = () => {
  const { isConnectionModalOpen } = $vendorsUI.value;
  const { name, isLoading } = $selectedVendor.value;
  return (
    <SimpleModal
      isShow={isConnectionModalOpen}
      onClose={() => $vendorsUI.update({ isConnectionModalOpen: false })}
      size="lg"
    >
      <div className="bg-gray-50 rounded-4">
        {isLoading ? (
          <Loadable template="component" signal={$selectedVendor} />
        ) : (
          <>
            <Row className="m-0 py-3 bg-warning py-24">
              <Col className="px-40 d-flex justify-content-between">
                <h2 className="pe-16 mb-0">Connect with <span className="fw-800">{name}</span> to view your statement</h2>
                <div className="mt-8 ps-16 cursor-pointer" onClick={() => $vendorsUI.update({ isConnectionModalOpen: false })}>
                  <FontAwesomeIcon icon={faX} className="text-dark" />
                </div>
              </Col>
            </Row>
            <SidePanelConnections type="vendor" />
          </>
        )}
      </div>
    </SimpleModal>
  );
};

export default VendorConnectionModal;
