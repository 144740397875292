import $user from 'signals/User.signals';
import { historyPush } from 'utils/history';

export const firstAvailableOrganization = () => {
  let tempOrganization = $user.value.user?.organizations?.length > 0 ? $user.value.user?.organizations?.find((org) => org?.role === 'OWNER') : null;
  if (!tempOrganization) {
    tempOrganization = $user.value.user?.organizations?.find((org) => org?.organization?.subscription_level !== 1);
  }

  if (!tempOrganization && $user.value?.user?.id) {
    // USER HAS NO VALID ORGANIZATIONS
    historyPush('/profile');
  }
  return tempOrganization;
};

export default firstAvailableOrganization;
